import React, { useContext } from "react";
import UserContext from "../../contexts/UserContext";
import { StyledButton } from "../../components/Form.styles";
import { STable, STBody, STBodyTR, STD, STH, STHead, STHeadTR } from "./styles";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { AiFillCopy } from "react-icons/ai";

import get from "lodash.get";
import { useNavigate } from "react-router-dom";

import { showErrorToast } from "../../utils/errorToast";
import { showSuccessToast } from "../../utils/successToast";

const Table = ({ txns }) => {
  const { ChainlinkConf } = useContext(UserContext);
  const navigate = useNavigate();

  let chainlinkConf;
  let chainUrlSegment;
  const networkVersion = get(window, "ethereum.networkVersion");
  switch (networkVersion) {
    case "1":
      chainUrlSegment = ``;
      chainlinkConf = ChainlinkConf[networkVersion];
      break;
    case "5":
      chainUrlSegment = `goerli.`;
      chainlinkConf = ChainlinkConf[networkVersion];
      break;
    default:
      console.error("Unsupported Network");
  }

  const keys =
    txns.length > 0 ? ["timeStamp", "hash", "contractAddress", "actions"] : [];

  return txns.length > 0 && txns ? (
    <>
      <STable>
        <STHead>
          <STHeadTR>
            {["#", ...keys].map((item, index) => (
              <STH key={index}>{item}</STH>
            ))}
          </STHeadTR>
        </STHead>
        <STBody>
          {txns.map((obj, index) => (
            <STBodyTR key={index}>
              <STD>{index + 1}</STD>
              {keys.map((item, index) => {
                if (item === "timeStamp") {
                  const value = new Date(obj[item] * 1000).toLocaleString();
                  return <STD key={index}>{value}</STD>;
                } else if (item === "hash") {
                  const value = obj[item];

                  return (
                    <STD key={index}>
                      <CopyToClipboard text={value} onCopy={() => {}}>
                        <div>
                          {value ? (
                            <span style={{ cursor: "pointer" }}>
                              {value.substring(0, 8)}...
                              <AiFillCopy />
                            </span>
                          ) : (
                            "n/a"
                          )}
                        </div>
                      </CopyToClipboard>
                    </STD>
                  );
                } else if (item === "contractAddress") {
                  const value = obj[item];

                  return (
                    <STD key={index}>
                      {value ? (
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`https://${chainUrlSegment}etherscan.io/address/${value}`}
                        >
                          <span style={{ cursor: "pointer" }}>
                            {value.substring(0, 8)}...
                            <AiFillCopy />
                          </span>
                        </a>
                      ) : (
                        "n/a"
                      )}
                    </STD>
                  );
                } else if (item === "actions") {
                  return (
                    <STD key={index}>
                      {obj["contractAddress"] ? (
                        <StyledButton
                          onClick={() => {
                            navigate(`/dashboard/contract/${obj["contractAddress"]}`);
                          }}
                        >
                          Detail
                        </StyledButton>
                      ) : (
                        "n/a"
                      )}
                    </STD>
                  );
                } else {
                  const value = obj[item];
                  return <STD key={index}>{value}</STD>;
                }
              })}
            </STBodyTR>
          ))}
        </STBody>
      </STable>
    </>
  ) : (
    <div style={{ textAlign: "center" }}>ø</div>
  );
};

export default Table;
