import React, { useContext, useState } from "react";
import UserContext from "../contexts/UserContext";
import Modal from "./Modal.js";
import get from "lodash.get";

import moment from "moment-timezone";
import { useForm } from "react-hook-form";

import {
  TitleSection,
  BalanceSection,
  InputSection,
  Col,
  Col2,
  Row,
  Row2,
  ButtonContainer,
  StyledButton,
  Title,
  Title2,
  NumberStyle,
  WarningStyle,
  Desc,
  StyleModal,
  FieldInput,
} from "./Form.styles";

import { ContractFactory, ethers } from "ethers";

import celebIcon from "../assets/confetti-outline.gif";

import { showErrorToast } from "../utils/errorToast";

import contractExport from "../contractExports/YouTubePerformanceBounty/YouTubePerformanceBounty.json";
// import contractExportFlattened from "../contractExports/YouTubePerformanceBounty/YouTubePerformanceBountyFlattened.sol";

const Form = () => {

  const {
    register,
    trigger,
    watch,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    control,
    setValue,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      funder: "0x43F65FaB21AF387cb8ea99138DD6bFbfd3437610",
      receiver: "0x43F65FaB21AF387cb8ea99138DD6bFbfd3437610",
      ethBounty: 0.001,
      date: moment().add("30", "m").format("YYYY-MM-DDTHH:MM"),
      socialURL: "https://www.youtube.com/watch?v=ftuu7B0B7EM",
      desiredViews: 30,
      desiredComments: 1
    },
  });

  const { currentBalance, isConnected, ChainlinkConf } =
    useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  // const [funder, setFunder] = useState(
  //   "0x43F65FaB21AF387cb8ea99138DD6bFbfd3437610"
  // );
  // const [receiver, setReceiver] = useState(
  //   "0x43F65FaB21AF387cb8ea99138DD6bFbfd3437610"
  // );
  // const [ethBounty, setEthBounty] = useState(0.001);
  // const [socialURL, setSocialURL] = useState(
  //   "https://www.youtube.com/watch?v=ftuu7B0B7EM"
  // );
  // const [date, setDate] = useState(moment().format("YYYY-MM-DDTHH:MM:SS"));
  // const [desiredViews, setDesiredViews] = useState(50);
  // const [numSubscribers, setNumSubscribers] = useState();
  // const [desiredComments, setDesiredComments] = useState(5);
  // const [radio, setRadio] = useState("youtube");

  const onSubmit = async (data) => {
    try {
      const {
        funder,
        receiver,
        ethBounty,
        socialURL,
        date,
        desiredViews,
        numSubscribers,
        desiredComments,
        radio
      } = data

      let chainlinkConf;
      let chainUrlSegment;
      const networkVersion = get(window, "ethereum.networkVersion");
      console.log("handleSubmit", networkVersion);

      switch (networkVersion) {
        case "1":
          chainUrlSegment = ``;
          chainlinkConf = ChainlinkConf[networkVersion];
          break;
        case "5":
          chainUrlSegment = `goerli.`;
          chainlinkConf = ChainlinkConf[networkVersion];
          break;
        default:
          throw new Error("Unsupported Network");
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const url = new URL(socialURL);
      const videoId = url.searchParams.get("v");

      const dateFormatted = Math.floor(new Date(date) / 1000);

      const factory = new ContractFactory(
        contractExport.abi,
        contractExport.bytecode,
        signer
      );

      console.log("initializing contract with:", {
        funder,
        receiver,
        videoId,
        desiredViews,
        desiredComments,
        dateFormatted,
        LINK_address: chainlinkConf.LINK_token,
        Oracle_address: chainlinkConf.Oracle,
        Chainlink_node_JobID: chainlinkConf.JobID,
        Chainlink_node_fee: ethers.BigNumber.from(chainlinkConf.LINK_fee),
      });

      const contract = await factory.deploy(
        funder,
        receiver,
        videoId,
        desiredViews,
        desiredComments,
        dateFormatted,
        chainlinkConf.LINK_token,
        chainlinkConf.Oracle,
        chainlinkConf.JobID,
        ethers.BigNumber.from(chainlinkConf.LINK_fee),
        {
          value: ethers.utils.parseEther(ethBounty.toString()),
        }
      );

      setMsg(
        <>
          <h2>Deployment Sent</h2>
          <>
            <img
              src={celebIcon}
              alt="Celeb"
              width="1em"
              style={{
                width: "10em",
                height: "10em",
              }}
            />
          </>
          <h3>
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://${chainUrlSegment}etherscan.io/tx/${contract.deployTransaction.hash}`}
            >
              View on Etherscan
            </a>
          </h3>
        </>
      );
      setOpen(true);
    } catch (e) {
      showErrorToast("Error occurred during contract deployment");
      console.error(e);
    }
  };
  const close = (open) => {
    setOpen(!open);
  };
  const reset = () => {
    setOpen(!open);
  };

  return (
    <>
      <TitleSection>
        <Title noBottomMargin>Terms</Title>
        <Desc>
          The parties involved agree to the transfer of funds if the social
          performance metrics are reached.
        </Desc>
      </TitleSection>
      <BalanceSection>
        <Title2>Balance</Title2>
        {isConnected ? (
          currentBalance && (
            <NumberStyle>
              {currentBalance
                ? `${ethers.utils.formatEther(currentBalance)} ETH`
                : "ø"}
            </NumberStyle>
          )
        ) : (
          <WarningStyle>No wallet connected.</WarningStyle>
        )}
      </BalanceSection>
      {open && (
        <StyleModal>
          <Modal msg={msg} close={close} setOpen={setOpen} reset={reset} />
        </StyleModal>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Col>
          <InputSection>
            <Title2>Wallets</Title2>
            <label>Funder</label>
            <FieldInput
              {...register("funder", { required: true })}
              placeholder="Address"
              className="field"
              type="text"
              onChange={(e) => setValue('funder', e.target.value)}
            />
            <label>Receiver</label>
            <FieldInput
              {...register("receiver", { required: true })}
              placeholder="Address"
              className="field"
              type="text"
              onChange={(e) => setValue('receiver', e.target.value)}
            />
          </InputSection>
          <InputSection>
            <Title2>Bounty</Title2>
            <label>ETH</label>
            <FieldInput
              {...register("ethBounty", { required: true })}
              className="field"
              inputMode="decimal"
              type="number"
              placeholder="Amount of ETH"
              min="0"
              step="any"
            />
            <label>Metrics should be reached by:</label>
            <FieldInput
              {...register("date", { required: true })}
              className="field"
              type="datetime-local"
              // onChange={(e) => setValue('date', e.target.value)}
            />
          </InputSection>
        </Col>
        <Col>
          <InputSection>
            <Title2>Social Platform Oracle</Title2>
            <Row>
              <Col2>
                <label>Tiktok</label>
                <Row2>
                  <input
                    disabled
                    type="radio"
                    value="tiktok"
                    checked={getValues('radio') === "tiktok"}
                    // onChange={(e) => setValue('radio', e.target.value)}
                  />
                </Row2>
              </Col2>
              <Col2>
                <label>Youtube</label>
                <Row2>
                  <input
                    {...register("radio", { required: true })}
                    type="radio"
                    value="youtube"
                    checked={true}
                    onChange={(e) => setValue('radio', e.target.value)}
                  />
                </Row2>
              </Col2>
              <Col2>
                <label>Instagram</label>
                <Row2>
                  <input
                    disabled
                    type="radio"
                    value="instagram"
                    // checked={radio === "instagram"}
                    // onChange={(e) => setRadio(e.target.value)}
                    checked={getValues('radio') === "instagram"}
                    // onChange={(e) => setValue('radio', e.target.value)}
                  />
                </Row2>
              </Col2>
            </Row>
            <label></label>
            <FieldInput
              {...register("socialURL", { required: true })}
              placeholder="URL"
              className="field"
              type="text"
              // onChange={(e) => setSocialURL(e.target.value)}
            />
          </InputSection>
          <InputSection>
            <Title2>Performance Metrics</Title2>
            <label>Views</label>
            <FieldInput
              {...register("desiredViews", { required: true })}
              className="field"
              inputMode="numeric"
              type="number"
              placeholder="Amount of Views"
              min="0"
              step="1"
            />
            <label>Comments</label>
            <FieldInput
              {...register("desiredComments", { required: true })}
              className="field"
              inputMode="numeric"
              type="number"
              placeholder="Amount of Comments"
              min="0"
              step="1"
            />
            {/* <label>Subscribers</label>
            <FieldInput
              disabled
              className="field"
              name="numSubscribers"
              value={numSubscribers}
              onChange={(e) => setNumSubscribers(e.target.value)}
              inputMode="numeric"
              type="number"
              placeholder=""
              min="0"
              step="1"
            /> */}
          </InputSection>
        </Col>
        <Col>
          <InputSection></InputSection>
          <ButtonContainer>
            <StyledButton id="submit">Deploy</StyledButton>
          </ButtonContainer>
        </Col>
      </form>
    </>
  );
};
export default Form;
