import styled from "styled-components";

import { device } from "../device";

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 10px 13px;
  background-color: white;
  border-radius: 10px;
  margin: 65px 20px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
  width: auto;
  max-width: calc(100% - 40px);

  @media ${device.mobileM} {
    margin-top: 70px;
    padding: 15px 18px;
  }
  @media ${device.mobileL} {
    margin-top: 80px;
  }
  @media ${device.tablet} {
    padding: 15px 60px !important;
    margin-top: 100px;
  }
  @media ${device.laptop} {
    margin-top: 120px;
  }
`;

export const ViewSection = styled.div`
    overflow-x: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
      
    -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
`;

export const TitleSection = styled.div``;

export const BalanceSection = styled.div``;

export const NumberStyle = styled.h2`
  color: #60ba61;
  font-family: "Yantramanav-Bold";
  font-size: 1em;
  margin-top: 0em;
  margin-bottom: 0em;
  @media ${device.tablet} {
    font-size: 1.5em;
    margin-bottom: 0em;
  }
`;

export const WarningStyle = styled.h2`
  color: #60ba61;
  font-family: "Yantramanav-Bold";
  font-size: 1em;
  margin-top: 0em;
  margin-bottom: 0em;
  @media ${device.tablet} {
    font-size: 1.5em;
    margin-bottom: 0em;
  }
`;

export const InputSection = styled.div`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: column;
  // justify-content: center;
`;

export const Col = styled.div`
  // background-color: pink;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  display: flex;
  flex-direction: column;
  // align-items: space-evenly;
  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Col2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  align-items: center;
  // padding-top: 40px;

  @media ${device.tablet} {
    justify-content: flex-end;
    // padding-top: 20px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const StyledButton = styled.button`
  width: 120px;
  font-family: "Yantramanav-Bold";
  filter: drop-shadow(2px 1px 2px rgba(0, 0, 0, 0.3));
  background-color: #60ba61;
  ${(props) => props.altStyle && "background-color: white;"}
  color: white;
  ${(props) => props.altStyle && "color: black;"}
  ${(props) => props.disabled && "opacity: 0.5;"}
  font-size: 1em;
  padding: 6px 2px;
  border-radius: 4px;
  ${(props) => props.left && "border-radius: 4px 0px 0px 4px;"}
  ${(props) => props.middle && "border-radius: 0px;"}
  ${(props) => props.right && "border-radius: 0px 4px 4px 0px;"}
  ${(props) => props.margin && "margin: 0.5em;"}
  border: none;
  text-align: center;
  letter-spacing: 0.6px;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  transition: 0.2s all;
`;

export const CTAButton = styled.button`
  width: ${(props) => props.doubleWidth ? "240px" : "120px"};
  font-family: "Yantramanav-Bold";
  filter: drop-shadow(2px 1px 2px rgba(0, 0, 0, 0.3));
  background-color: #60ba61;
  ${(props) => props.altStyle && "background-color: white;"}
  color: white;
  ${(props) => props.altStyle && "color: black;"}
  ${(props) => props.disabled && "opacity: 0.5;"}
  font-size: 1.5em;
  padding: 6px 2px;
  border-radius: 4px;
  ${(props) => props.left && "border-radius: 4px 0px 0px 4px;"}
  ${(props) => props.middle && "border-radius: 0px;"}
  ${(props) => props.right && "border-radius: 0px 4px 4px 0px;"}
  ${(props) => props.margin && "margin: 0em;"}
  border: none;
  text-align: center;
  letter-spacing: 0.6px;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  transition: 0.2s all;

  @media ${device.laptop} {
    font-size: 2em;
  }
`;

export const Title = styled.h1`
  font-size: 2.5em;
  font-family: "Yantramanav-Bold";
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  ${(props) => props.textAlignCenter && "text-align: center;"}
  ${(props) => props.noBottomMargin && "margin-bottom: 0em;"}
  ${(props) => props.centered && "text-align: center;"}
`;

export const Title2 = styled.h2`
  font-size: 1.5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: "Yantramanav-Bold";
  // @media ${device.laptop} {
  //     margin-top: 1em;
  //     margin-bottom: 1em;
  // }
`;

export const Desc = styled.h3`
  font-size: 14px;
  color: #090909;

  @media ${device.mobileL} {
    font-size: 15px;
  }
`;

export const StyleModal = styled.div`
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(
    0,
    0,
    0,
    0.4
  ); /* Overlay effect: translucent background: black w/ partial opacity */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  position: fixed; /* Fix position on the top-left corner*/
  top: 0;
  left: 0;
  right: 0;
  overflow: auto; /* Enable scroll if needed */
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FieldInput = styled.input`
  height: 30px;
  width: 100%;
  border: double 1.5px transparent;
  border-radius: 2px;
  background-image: linear-gradient(white, white),
    linear-gradient(30deg, #8eca7d, #cde768);
  background-origin: border-box;
  background-clip: padding-box, border-box;

  :disabled {
    opacity: 0.4;
  }

  @media ${device.tablet} {
    width: 285px;
  }
`;
