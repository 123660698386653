// import clogo from '../assets/clogo.png';
import hlogo from '../assets/heralogo.svg';
import React,{ useContext} from "react";
import UserContext from '../contexts/UserContext';

import {
    StyledHeader,
    StyledLogo2,
    ConnectButton
} from './Header.styles'
import { useNavigate } from "react-router-dom";

const Header = () => {
    const { connectWallet, isConnected, disconnect} = useContext(UserContext)
    const navigate = useNavigate();

    return(
        <StyledHeader>
            <StyledLogo2 onClick={() => {
                navigate('/')
                window.scrollTo(0, 0)
            }} src={hlogo} alt="logo" />
            {isConnected ? <ConnectButton id="submit" onClick={disconnect}>Disconnect</ConnectButton> :
             <ConnectButton id="submit" onClick={connectWallet}>Connect Wallet</ConnectButton>
            }
        </StyledHeader>
    )

}
export default Header;