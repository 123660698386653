
// import Header from "./components/Header";
// import { Page, Row } from "./App.styles";
// import { ethers } from "ethers";
// import UserContext from "./contexts/UserContext";
// import { ChainlinkConf } from "./config/chainlink.conf";
import Container from "../components/Container";
import ContractState from "../components/ContractState";

function Contract() {
  return (
    <ContractState/>
  );
}

export default Contract;
