import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import undrawFast from "../../assets/webspeed.png";

export function Fast() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section
      style={{
        paddingBottom: "2em",
        backgroundColor: "rgb(176, 216, 32)",
      }}
      id=""
      ref={ref}
    >
      <Container>
        <Row className="justify-content-center text-center">
          <Col
            xs={8}
            style={{
              transform: isInView ? "none" : "translateX(-200px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.8s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
          >
            <img
              src={undrawFast}
              alt="logo"
              style={{
                width: "100%",
              }}
            />
            <h1 className="fw-light mb-2">Fast</h1>
            <p className="mb-2">
              <span className="fw-bold">Less stress, more time for you</span>
            </p>
            <p>
              Marketers are 100% guaranteed to get paid as soon as reach metrics
              are met
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
