import React from "react";
import styled from "styled-components";

import { AiOutlineClose } from "react-icons/ai";

import { device } from "../device";

const StyledText = styled.div`
  padding: 0.5em;
  margin-bottom: 1em;

  h2 {
    font-size: 2em;
    margin-bottom: 0em;
  }

  @media ${device.tablet} {
    padding: 10px;
    margin-bottom: 10px;
  }
`;

const Modal = ({ msg, reset, data }) => {
  return (
    <div id="modal-content">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "rgb(96, 186, 97)",
            borderRadius: "4px",
            padding: "0.5em",
            border: "none",
            color: "white",
            filter: "drop-shadow(rgba(0, 0, 0, 0.3) 2px 1px 2px)",
          }}
          onClick={(e) => reset(e)}
        >
          <AiOutlineClose />
        </button>
      </div>
      <StyledText>{msg}</StyledText>
    </div>
  );
};
export default Modal;
