import React, { useState, useEffect } from "react";
import Container from "../components/Container";

function Main() {
  return (
    <Container/>
  );
}

export default Main;
