export const ChainlinkConf = {
    5: { // ChainID 5 is Goerli
        "LINK_token": "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
        "Oracle": "0x6c2e87340Ef6F3b7e21B2304D6C057091814f25E",
        "JobID": "8ad1b333682b46d197483cf598beb59d",
        "LINK_fee": "100000000000000000" // 0.1 LINK in Juels
    }, 
    1: { // ChainID 1 is ETH Mainnet
        "LINK_token": "0x514910771AF9Ca656af840dff83E8264EcF986CA",
        "Oracle": "0x1db329cDE457D68B872766F4e12F9532BCA9149b",
        "JobID": "8ad1b333682b46d197483cf598beb59d",
        "LINK_fee": "1400000000000000000" // 1.4 LINK in Juels
    }
}