import React from "react";

import { Route, Routes, Navigate, Outlet } from "react-router-dom";

import { TopHeaderWithDynamicPage } from "./layouts/topHeaderWithDynamicPage";
import App from "../src/App";

import Container from "./components/Container";
import Main from "./pages/main";
import Contract from "./pages/contract";
import Presentation from "./pages/landingPage";

const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Presentation />}>
        </Route>
        <Route path="/dashboard/" element={<TopHeaderWithDynamicPage />}>
          {/* <Route path="" element={<Container />} /> */}
          <Route path="" element={<Main />} />
          <Route path="contract/:address" element={<Contract />} />
        </Route>
        <Route path="*" element={<>Not Found</>} />
      </Routes>
    </>
  );
};

export default Router;
