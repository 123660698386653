import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import undrawSocial from "../../assets/web3.png";

export function Section3() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section
      style={{
        paddingBottom: "2em",
      }}
      id=""
      ref={ref}
    >
      <Container>
        <Row className="justify-content-end align-items-center">
          <Col
            lg={4}
            style={{
              transform: isInView ? "none" : "translateX(-200px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.8s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
              textAlign: "right",
            }}
          >
            <img style={{
              width: '100%'
            }} src={undrawSocial} alt="social" />
          </Col>
          <Col
            lg={7}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'end',
              transform: isInView ? "none" : "translateX(-200px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.8s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
              textAlign: "right",
            }}
          >
            <h1>Less Risk</h1>
            <p className="mb-2 lead fw-bold">
              Performance-based social media marketing
            </p>
            <p className="mb-4">
              If the terms of your contract are not met then your money stays
              with you
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
