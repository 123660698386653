import React from "react";
import { ViewSection, Title } from "./Form.styles";

function Info() {
  return (
    <>
      <ViewSection>
        <Title>About</Title>
        <p>
         Hera provides easy-to-use smart contracts. Our mission is to introduce the benefits of smart contracts in a way that is accessible and exciting.
        </p>
        <Title>Creator Contract</Title>
        <p>
        The Creator contract allows ETH payouts based on the performance of youtube content. 
        The contract holds a bounty set by the user in ETH that can be claimed by  a `Recipient address` if the provided YouTube video achieves a certain amount of views OR comments by or before a specified date.
        If the YouTube video does NOT achieve a certain amount of views and comments by the deadline specified, then the `Funder address` will be able to reclaim the bounty.
        </p>
        <Title>How to use</Title>
        <p>The Hera. app currently only supports MetaMask.
            Sign in by connecting your metamask account.
            Once signed in, Click create and fill out the required field for the contract. 
            Click deploy once all fields are filled out. 
            The contract needs to be funded with 1.4 LINK inorder to complete its function.
            In the HISTORY tab, you can view the status of your contract and fund it with LINK.
            You must have LINK stored in your meta mask wallet. 
      </p>
        <Title>Additional Info</Title>
        <p>
        For questions and support please contact Team@heracontracts.com
        </p>
      </ViewSection>
    </>
  );
}

export default Info;
