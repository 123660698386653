import React from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { BgAnimation } from "../components/LandingPageSections/BgAnimation";
import { Hero } from "../components/LandingPageSections/Hero2";
import { Secure } from "../components/LandingPageSections/Secure";
import { Fast } from "../components/LandingPageSections/Fast";
import { Section3 } from "../components/LandingPageSections/Section3";

const Presentation = () => {
  return (
    <>
      <Hero></Hero>
      <Section3></Section3>
      <Fast></Fast>
      <Secure></Secure>
    </>
  );
};

export default Presentation;
