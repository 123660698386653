import styled from "styled-components";
import background from "./assets/App_BG.png";


export const Page = styled.div`
    background-image: url(${background});
    backgroundSize:"contain";
    width: 100%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    min-height:100vh;
`;

export const Row = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
`;