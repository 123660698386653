import styled from "styled-components";
import { device } from "../device";

export const StyledHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    top: 0;
    z-index: 100;
    position:fixed;
    background-color: white;
    width: 100vw;
    box-shadow: 0 4px 2px -2px rgba(91, 169, 59,.3);
    @media ${device.mobileL} {
        padding: 10px 30px;
    }
`;

export const StyledLogo1 = styled.img`
    height: 35px;
    @media ${device.mobileL} {
        height: 45px;
    }
    @media ${device.laptop} {
    height:65px;
    }
`;

export const StyledLogo2 = styled.img`
    height: 25px;
    @media ${device.mobileL} {
        height: 35px;
    }
    @media ${device.laptop} {
        height: 50px;
    }
`;

export const ConnectButton = styled.button`
    width: 100px;
    font-family: 'Yantramanav-Bold';
    filter: drop-shadow(2px 1px 2px rgba(0,0,0,.3));
    background-color: #60ba61;
    color: white;
    font-size: 12px;
    padding: 10px 2px;
    border-radius: 4px;
    border: none;
    letter-spacing: .6px;
    cursor: pointer;
    outline: none;
    transition: 0.2s all;
    @media ${device.mobileL} {
        width: 150px;
        font-size: 15px;
    }
`;