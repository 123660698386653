import React, { Component, ReactNode } from "react";
// import { logErrorToMyService } from "../services/errorReporter";

// React.Component<{name: string}, person>

// interface P {
//   children: any;
// }

// interface S {
//   hasError: boolean;
// }

export class ErrorBoundary extends Component {
  state = { hasError: false };

//   constructor(props) {
//     super(props);
//   }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.error(error)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return "Something went wrong.";
    }

    // return this.props.children;
    const { hasError, error, info } = this.state;
    const { children } = this.props;

    return hasError ? "ERROR" : children;
  }
}
