import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datetime/css/react-datetime.css";
import "./App.css";
import Header from "./components/Header";
import { Page, Row } from "./App.styles";
import { ethers } from "ethers";
import UserContext from "./contexts/UserContext";
import { ChainlinkConf } from "./config/chainlink.conf";
import Container from "./components/Container";
import Router from "./Router";
import { useLocation } from 'react-router-dom'

import { lightTheme } from "./components/Table/theme";
import { ThemeProvider } from "styled-components";
import { showErrorToast } from "./utils/errorToast";
import background from "./assets/pic2.png";

function App() {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [currentBalance, setCurrentBalance] = useState(null);
  const [isConnected, setisConnected] = useState(false);
  const location = useLocation();
  
  const { pathname } = location;

  async function connectWallet() {
    try {
      console.log("--- connectWallet ---");

      const { ethereum } = window;
      if (ethereum.isMetaMask) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const accounts = await provider.send("eth_requestAccounts", []);
        const balance = await provider.getBalance(accounts[0]);

        console.log("accounts[0]", accounts[0]);

        setCurrentAccount(accounts[0]);
        setCurrentBalance(balance);
        setisConnected(true);
      } else {
        alert("Install MetaMask");
      }
    } catch (e) {
      showErrorToast("Error connecting to wallet");
    }
  }

  async function disconnect() {
    setCurrentBalance(null);
    setCurrentAccount(null);
    setisConnected(false);
  }

  useEffect(() => {}, [isConnected]);

  useEffect(() => {
    console.log('pathname', pathname)

    if (pathname === "/") {

    } else {
      connectWallet()
    }
  }, [])

  return (
    <ThemeProvider theme={lightTheme}>
      <UserContext.Provider
        value={{
          connectWallet,
          currentAccount,
          currentBalance,
          isConnected,
          disconnect,
          ChainlinkConf,
        }}
      >
        <Router></Router>
      </UserContext.Provider>
    </ThemeProvider>
  );
}

export default App;
