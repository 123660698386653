import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import { AiOutlineReload } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { showErrorToast } from "../utils/errorToast";
import { ErrorBoundary } from "../utils/ErrorBoundary";

import UserContext from "../contexts/UserContext";

import get from "lodash.get";

import {
  StyledCard,
  TitleSection,
  Title,
  Desc,
  StyledButton,
} from "./Form.styles";

import PrintFlatJson from "./PrintFlatJson";

import LINK_token_ABI from "../contractExports/LINK_token/LINK_token.json";
import YouTubePerformanceBounty from "../contractExports/YouTubePerformanceBounty/YouTubePerformanceBounty.json";

import loadingIcon from "../assets/Loader.svg";
import { showSuccessToast } from "../utils/successToast";

const ContractState = () => {
  const { ChainlinkConf, currentAccount } = useContext(UserContext);
  const navigate = useNavigate();
  let { address } = useParams();
  let [state, setState] = useState({
    val: null,
    loading: true,
    err: null,
  });
  const [fetchCount, setFetchCount] = useState(0);

  let chainlinkConf;
  let chainUrlSegment;
  const networkVersion = get(window, "ethereum.networkVersion");
  switch (networkVersion) {
    case "1":
      chainUrlSegment = ``;
      chainlinkConf = ChainlinkConf[networkVersion];
      break;
    case "5":
      chainUrlSegment = `goerli.`;
      chainlinkConf = ChainlinkConf[networkVersion];
      break;
    default:
      console.error("Unsupported Network");
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        if (currentAccount) {
          console.info("Get Metrics from Oracle");
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();

          const contract = new ethers.Contract(
            address,
            YouTubePerformanceBounty.abi,
            signer
          );

          const funder = await contract.funder();
          const receiver = await contract.receiver();
          const deadline = await contract.deadline();
          const views = await contract.views();
          const comments = await contract.comments();
          const videoId = await contract.videoId();
          const desiredViewsCount = await contract.desiredViewsCount();
          const desiredCommentsCount = await contract.desiredCommentsCount();
          const requestMetricsFulfillCount =
            await contract.requestMetricsFulfillCount();
          let balance = (
            await provider.getBalance(contract.address)
          ).toString();
          balance = Math.floor(Number.parseInt(balance));
          const balanceFormatted = `${ethers.utils.formatEther(
            (await provider.getBalance(contract.address)).toString()
          )} ETH`;

          const LINK_contract = new ethers.Contract(
            chainlinkConf["LINK_token"],
            LINK_token_ABI,
            provider
          );
          let LINK_balance = await LINK_contract.balanceOf(contract.address);
          LINK_balance = `${LINK_balance / 1000000000000000000} LINK`;
          let LINK_fee = `${
            chainlinkConf["LINK_fee"] / 1000000000000000000
          } LINK`;

          console.log(
            funder,
            receiver,
            deadline,
            views,
            comments,
            videoId,
            desiredViewsCount,
            desiredCommentsCount.toString(),
            balance,
            balanceFormatted,
            LINK_balance,
            LINK_fee
          );

          setState({
            val: {
              address: address,
              bounty: balanceFormatted,
              balance,
              funder,
              receiver,
              now: Math.floor(new Date().getTime() / 1000),
              nowFormatted: new Date().toLocaleString(),
              deadline: Math.floor(Number.parseInt(deadline.toString())),
              deadlineFormatted: new Date(
                Number.parseInt(deadline.toString()) * 1000
              ).toLocaleString(),
              videoId,
              views: Number.parseInt(views.toString()),
              comments: Number.parseInt(comments.toString()),
              desiredViewsCount: Number.parseInt(desiredViewsCount.toString()),
              desiredCommentsCount: Number.parseInt(
                desiredCommentsCount.toString()
              ),
              oracleRequests: requestMetricsFulfillCount.toString(),
              LINK_balance: LINK_balance,
              LINK_fee: LINK_fee,
            },
            loading: false,
            err: null,
          });
        }
      } catch (e) {
        showErrorToast("Error occurred while fetching/parsing Contract State");
        console.error(e);
      }
    }, 5000);

    return () => clearInterval(interval);

    // showSuccessToast("Loading Contract State...");
    // currentAccount && fetch();
  }, [address, fetchCount, currentAccount]);

  useEffect(() => {
    async function fetch() {
      console.log('__!!__', currentAccount)

      try {
        if (currentAccount) {
          console.info("Get Metrics from Oracle");
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();

          const contract = new ethers.Contract(
            address,
            YouTubePerformanceBounty.abi,
            signer
          );

          const funder = await contract.funder();
          const receiver = await contract.receiver();
          const deadline = await contract.deadline();
          const views = await contract.views();
          const comments = await contract.comments();
          const videoId = await contract.videoId();
          const desiredViewsCount = await contract.desiredViewsCount();
          const desiredCommentsCount = await contract.desiredCommentsCount();
          const requestMetricsFulfillCount =
            await contract.requestMetricsFulfillCount();
          let balance = (
            await provider.getBalance(contract.address)
          ).toString();
          balance = Math.floor(Number.parseInt(balance));
          const balanceFormatted = `${ethers.utils.formatEther(
            (await provider.getBalance(contract.address)).toString()
          )} ETH`;

          const LINK_contract = new ethers.Contract(
            chainlinkConf["LINK_token"],
            LINK_token_ABI,
            provider
          );
          let LINK_balance = await LINK_contract.balanceOf(contract.address);
          LINK_balance = `${LINK_balance / 1000000000000000000} LINK`;
          let LINK_fee = `${
            chainlinkConf["LINK_fee"] / 1000000000000000000
          } LINK`;

          console.log(
            funder,
            receiver,
            deadline,
            views,
            comments,
            videoId,
            desiredViewsCount,
            desiredCommentsCount.toString(),
            balance,
            balanceFormatted,
            LINK_balance,
            LINK_fee
          );

          setState({
            val: {
              address: address,
              bounty: balanceFormatted,
              balance,
              funder,
              receiver,
              now: Math.floor(new Date().getTime() / 1000),
              nowFormatted: new Date().toLocaleString(),
              deadline: Math.floor(Number.parseInt(deadline.toString())),
              deadlineFormatted: new Date(
                Number.parseInt(deadline.toString()) * 1000
              ).toLocaleString(),
              videoId,
              views: Number.parseInt(views.toString()),
              comments: Number.parseInt(comments.toString()),
              desiredViewsCount: Number.parseInt(desiredViewsCount.toString()),
              desiredCommentsCount: Number.parseInt(
                desiredCommentsCount.toString()
              ),
              oracleRequests: requestMetricsFulfillCount.toString(),
              LINK_balance: LINK_balance,
              LINK_fee: LINK_fee,
            },
            loading: false,
            err: null,
          });
        }
      } catch (e) {
        showErrorToast("Error occurred while fetching/parsing Contract State");
        console.error(e);
      }
    }

    fetch();

    // showSuccessToast("Loading Contract State...");
    // currentAccount && fetch();
  }, [currentAccount]);

  // console.log(get(state, "val.views", 0));
  // console.log(get(state, "val.desiredViewsCount", 0));
  // console.log(get(state, "val.comments", 0));
  // console.log(get(state, "val.desiredCommentsCount", 0));
  // console.log(get(state, "val.now", 0));
  // console.log(get(state, "val.deadline", 0));
  // console.log(
  //   "past Deadline?",
  //   get(state, "val.now", 0) > get(state, "val.deadline", 0)
  // );
  // console.log("view less than desired?");
  // console.log(
  //   get(state, "val.views", 0) < get(state, "val.desiredViewsCount", 0) &&
  //     get(state, "val.comments", 0) <
  //       get(state, "val.desiredCommentsCount", 0) &&
  //     get(state, "val.now", 0) > get(state, "val.deadline", 0)
  // );

  // console.log(
  //   "->",
  //   !(get(state, "val.views", 0) < get(state, "val.desiredViewsCount", 0) &&
  //   get(state, "val.comments", 0) < get(state, "val.desiredCommentsCount", 0) &&
  //   get(state, "val.now", 0) > get(state, "val.deadline", 0) &&
  //   get(state, "oracleRequests", 0) > 0 &&
  //   get(state, "balance") > 0
  //     ? true
  //     : false)
  // );

  // console.log(
  //   !((get(state, "val.views") >= get(state, "val.desiredViewsCount") ||
  //     get(state, "val.comments") >= get(state, "val.desiredCommentsCount")) &&
  //   get(state, "val.oracleRequests") > 0 &&
  //   get(state, "val.balance") > 0
  //     ? true
  //     : false)
  // );

  return (
    <ErrorBoundary>
      <StyledCard>
        <div
          style={{
            marginTop: "1em",
            marginBottom: "0.5em",
            cursor: "pointer",
          }}
        >
          <BiArrowBack
            size={"2em"}
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <TitleSection>
          <Title noBottomMargin textAlignCenter>
            Contract Overview
          </Title>
        </TitleSection>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <a
            rel="noreferrer"
            target="_blank"
            href={`https://${chainUrlSegment}etherscan.io/address/${address}`}
          >
            <span style={{ cursor: "pointer" }}>View on Etherscan</span>
          </a>
        </div>
        <div
          style={{
            margin: "0.5em",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AiOutlineReload
            size={"2em"}
            onClick={() => {
              setFetchCount(fetchCount + 1);
            }}
          />
        </div>
        {state.loading ? (
          <>
            <img src={loadingIcon} alt="loader" />
          </>
        ) : (
          <>
            <PrintFlatJson obj={get(state, "val", {})} />
            <div
              style={{
                margin: "0.5em",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <a
                rel="noreferrer"
                href={`https://www.youtube.com/watch?v=${get(
                  state,
                  "val.videoId"
                )}`}
                target="_blank"
              >
                Watch Video
              </a>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <StyledButton
                margin
                onClick={async () => {
                  try {
                    const provider = new ethers.providers.Web3Provider(
                      window.ethereum
                    );
                    const signer = provider.getSigner();

                    const linkContract = new ethers.Contract(
                      chainlinkConf["LINK_token"],
                      LINK_token_ABI,
                      signer
                    );

                    let txnResp = await linkContract.transfer(
                      address,
                      ethers.BigNumber.from(chainlinkConf["LINK_fee"])
                    );

                    console.info(txnResp);

                    showSuccessToast(
                      "Request to Send LINK to Contract sent to Network"
                    );
                  } catch (e) {
                    showErrorToast("Error occurred while sending LINK");
                    console.error(e);
                  }
                }}
              >
                Send LINK
              </StyledButton>
              <StyledButton
                margin
                onClick={async () => {
                  try {
                    console.info("Get Metrics from Oracle");
                    const provider = new ethers.providers.Web3Provider(
                      window.ethereum
                    );
                    const signer = provider.getSigner();

                    const contract = new ethers.Contract(
                      address,
                      YouTubePerformanceBounty.abi,
                      signer
                    );

                    let txnResp = await contract.requestMetrics();

                    console.info(txnResp);
                    showSuccessToast(
                      "Request to have Chainlink oracle send latest video metrics to Contract sent"
                    );
                  } catch (e) {
                    showErrorToast(
                      "Error occurred while calling metrics oracle"
                    );
                    console.error(e);
                  }
                }}
              >
                Call Oracle
              </StyledButton>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <StyledButton
                margin
                disabled={
                  !(get(state, "val.views") <
                    get(state, "val.desiredViewsCount") &&
                  get(state, "val.comments") <
                    get(state, "val.desiredCommentsCount") &&
                  get(state, "val.now") > get(state, "val.deadline") &&
                  get(state, "val.oracleRequests") > 0 &&
                  get(state, "val.balance") > 0
                    ? true
                    : false)
                }
                onClick={async () => {
                  try {
                    console.info("Trigger Funder Withdrawal");
                    const provider = new ethers.providers.Web3Provider(
                      window.ethereum
                    );
                    const signer = provider.getSigner();

                    const contract = new ethers.Contract(
                      address,
                      YouTubePerformanceBounty.abi,
                      signer
                    );

                    let txnResp = await contract.funderWithdrawBounty();

                    console.info(txnResp);
                    showSuccessToast(
                      "Request to withdraw bounty to Funder sent"
                    );
                  } catch (e) {
                    showErrorToast(
                      "Error occurred while withdrawing bounty to funder"
                    );
                    console.error(e);
                  }
                }}
              >
                Withdraw Bounty <br />
                <small>to Funder</small>
              </StyledButton>
              <StyledButton
                margin
                disabled={
                  !((get(state, "val.views") >=
                    get(state, "val.desiredViewsCount") ||
                    get(state, "val.comments") >=
                      get(state, "val.desiredCommentsCount")) &&
                  get(state, "val.oracleRequests") > 0 &&
                  get(state, "val.balance") > 0
                    ? true
                    : false)
                }
                onClick={async () => {
                  try {
                    console.info("Trigger Receiver Withdrawal");
                    const provider = new ethers.providers.Web3Provider(
                      window.ethereum
                    );
                    const signer = provider.getSigner();

                    const contract = new ethers.Contract(
                      address,
                      YouTubePerformanceBounty.abi,
                      signer
                    );

                    let txnResp = await contract.receiverWithdrawBounty();

                    console.info(txnResp);
                    showSuccessToast(
                      "Request to withdraw bounty to Receiver sent"
                    );
                  } catch (e) {
                    showErrorToast(
                      "Error occurred while withdrawing bounty to receiver"
                    );
                    console.error(e);
                  }
                }}
              >
                Withdraw Bounty <br />
                <small>to Receiver</small>
              </StyledButton>
            </div>
          </>
        )}
      </StyledCard>
    </ErrorBoundary>
  );
};
export default ContractState;

/* 
<Dropdown.Item
  eventKey="verifyContractCode"
  onClick={async () => {
    try {
      console.log("___ Verify on Etherscan ___");

      const verificationReq = await axios.post(
        "https://api-goerli.etherscan.io/api",
        querystring.stringify({
          apiKey: process.env.REACT_APP_ETHERSCAN_API_KEY, // gave the values directly for testing
          module: "contract",
          action: "verifysourcecode",
          sourceCode: contractExportFlattened,
          contractaddress: obj["contractAddress"],
          contractname: "YouTubePerformanceBounty",
          codeformat: "solidity-single-file",
          compilerversion: "v0.8.7+commit.e28d00a7",
          optimizationUsed: 0,
        }),
        {
          "Content-Type":
            "application/x-www-form-urlencoded",
        }
      );

      console.log("verificationReq", verificationReq);
    } catch (e) {
      showErrorToast("Error occurred while programatically verifying contract with Etherscan");
      console.error(e);
    }
  }}
>
  Verify on Etherscan
</Dropdown.Item>
*/
