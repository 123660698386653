import React, { useEffect, useState, useContext } from "react";

import axios from "axios";

import { AiOutlineReload } from "react-icons/ai";
import UserContext from "../contexts/UserContext";
import get from "lodash.get";

import { ViewSection } from "./Form.styles";

import ScTable from "./Table/Table";
import { showErrorToast } from "../utils/errorToast";

import loadingIcon from "../assets/Loader.svg";

function History() {
  const { currentAccount } = useContext(UserContext);

  const [fetchCount, setFetchCount] = useState(0);
  const [txns, setTxns] = useState({
    val: [],
    loading: true,
    error: null,
  });

  let chainUrlSegment;
  const networkVersion = get(window, "ethereum.networkVersion");
  switch (networkVersion) {
    case "1":
      chainUrlSegment = `api.`;
      break;
    case "5":
      chainUrlSegment = `api-goerli.`;
      break;
    default:
      console.error("Unsupported Network");
  }

  useEffect(() => {
    async function fetch() {
      try {
        const res = await axios(`https://${chainUrlSegment}etherscan.io/api`, {
          params: {
            module: "account",
            action: "txlist",
            address: currentAccount,
            startblock: 0,
            endblock: 99999999,
            page: 1,
            offset: 10,
            sort: "desc",
            apikey: process.env.REACT_APP_ETHERSCAN_API_KEY,
          },
        });

        console.log("Txn history for current account:", res);

        if (get(res, "data.result") && Array.isArray(get(res, "data.result"))) {
          setTxns({
            val: get(res, "data.result", []),
            loading: false,
            error: null,
          });
        } else {
          throw Error("Error occurred while fetching transactions");
        }
      } catch (e) {
        showErrorToast("Error occurred while fetching transactions");
        console.log(e);
        setTxns({
          val: [],
          loading: false,
          error: e,
        });
      }
    }

    fetch();
  }, [fetchCount]);

  return (
    <>
      {txns.loading ? (
        <>
          <img src={loadingIcon} alt="loader" />
        </>
      ) : Array.isArray(txns.val) && txns.val.length > 0 ? (
        <ViewSection>
          <div
            style={{
              margin: "0.5em",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AiOutlineReload
              size={"2em"}
              onClick={() => {
                setFetchCount(fetchCount + 1);
              }}
            />
          </div>
          <ScTable txns={txns.val} />
        </ViewSection>
      ) : (
        <div
          style={{
            margin: "1em",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AiOutlineReload
            size={"2em"}
            onClick={() => {
              setFetchCount(fetchCount + 1);
            }}
          />
        </div>
      )}
    </>
  );
}

export default History;
