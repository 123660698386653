import React, { useState, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import Header from "../components/Header";
import { Page, Row } from "../App.styles";

// import UserContext from "../contexts/UserContext";
// import { ChainlinkConf } from "../config/chainlink.conf";
import Container from "../components/Container";

export const TopHeaderWithDynamicPage = (props) => {
  return (
    <>
      <Page>
        <Header />
        
        <Row>
          <Outlet />
        </Row>
      </Page>
    </>
  );
};
