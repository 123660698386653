import React, { useState } from 'react';

import {
    StyledCard,
    StyledButton,
    ButtonGroup
} from './Form.styles'

import Form from './Form';
import History from './History';
import Info from './Info';

const Container = () => {
    
    const [view, setView] = useState('create')
    
    console.log('render Container')

    return(
        
        <StyledCard>
            <ButtonGroup>
                <StyledButton left altStyle={view !== 'create'} onClick={() => setView("create")}>Create</StyledButton>
                <StyledButton middle altStyle={view !== 'history'} onClick={() => setView("history")}>History</StyledButton>
                <StyledButton right altStyle={view !== 'info'} onClick={() => setView("info")}>Info</StyledButton>
            </ButtonGroup>
            { view === "create" && <Form/> }
            { view === "history" && <History /> }
            { view === "info" && <Info /> }
        </StyledCard>
         
    )

}
export default Container;