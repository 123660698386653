import React from "react";
import get from "lodash.get";
import { AiFillCopy } from "react-icons/ai";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function PrintFlatJson(props) {
  let { obj } = props;

  let keys = Object.keys(obj);

  return keys.map((key) => {
    
    if (["funder", "receiver", "address"].includes(key)) {
      return (
        <div key={key}>
          <b>{key}:</b>{" "}
          <CopyToClipboard text={obj[key]} onCopy={() => {}}>
            
            <span style={{ cursor: "pointer" }}>
              {obj[key].substring(0, 8)}...
              <AiFillCopy />
            </span>
          </CopyToClipboard>
        </div>
      );
    } else {
      return (
        <div key={key}>
          <b>{key}:</b> <span>{obj[key]}</span>
        </div>
      );
    }
  });
}
