import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { CTAButton } from "../Form.styles";
import heraLogo from "../../assets/heralogo.svg";
import background from "../../assets/pic2.png";


export function Hero() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const navigate = useNavigate();

  return (
    <section
      className="landing"
      style={{backgroundImage: `url(${background})`, backgroundSize:"contain", backgroundRepeat:"no-repeat", width:"100%", backgroundposition:  "center"
      }}
      id=""
      ref={ref}
    >
      <Container>
        <Row>
          <Col>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
            <div className="d-flex align-items-bottom justify-content-center">
              <CTAButton
                style={{}}
                onClick={() => {
                  navigate("/dashboard");
                  window.scrollTo(0, 0)
                }}
                doubleWidth
              >
                Enter App
              </CTAButton>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
