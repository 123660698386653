import React, { useRef } from "react";
import { useInView } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import Ethereum from "../../assets/web2.png";

export function Secure() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section
      style={{
        paddingTop: "2em",
        paddingBottom: "2em",
      }}
      id=""
      ref={ref}
    >
      <Container>
        <Row className="">
          <Col
            lg={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              transform: isInView ? "none" : "translateX(-200px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.8s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
          >
            <h1>Secure</h1>
            <p className="mb-2 lead fw-bold">Powered by Ethereum</p>
            <p className="mb-4">
              <b>hera.</b> runs on the world's most battle-tested smart contract
              blockchain
            </p>
          </Col>
          <Col
            lg={6}
            style={{
              textAlign: "center",
              transform: isInView ? "none" : "translateX(-200px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.8s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
          >
            <img
              style={{
                maxHeight: '400px',
                width: "100%",
              }}
              src={Ethereum}
              alt="ethereum"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
